<template>
  <div>
    <upload-sidebar
      :is-upload-sidebar-active.sync="isUploadSidebarActive"
      @refresh-contracts="getContracts"
      :editing-contract-data="editingContractData"
      />

    <company-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :com-data="companyData"
      @get-company="getCompany"
    />

    <template v-if="companyData">
      <b-row class="match-height">
        <b-col cols="12" lg="6">
          <info-card :company-data="companyData" @edit-company="editCompany"/>
        </b-col>

        <b-col cols="12" lg="6">
          <relate-card 
          :company-data="companyData"
          :contract-data="contractData"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
        </b-col>
      </b-row>

      <contract-card
        :contract-data="contractData"
        @active-upload-sidebar="isUploadSidebarActive = true"
        @refresh-contracts="getContracts"
        @set-editing-contract-data="setEditingContractData($event)"
      />

      <sow-card
        :sow-data="sowData"
        @active-upload-sidebar="isUploadSidebarActive = true"
        @refresh-sows="getSows"
        @set-editing-contract-data="setEditingContractData($event)"
      />

    </template>

  </div>
</template>

<script>
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import InfoCard from './InfoCard.vue'
import TimelineCard from './TimelineCard.vue'
import RelateCard from './RelateCard.vue'
import CompanyEdit from '../system/CompanyEdit.vue'
import ContractCard from './ContractCard.vue'
import UploadSidebar from './UploadSidebar.vue'
import SowCard from './SowCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    InfoCard,
    TimelineCard,
    RelateCard,
    InvoiceList,
    CompanyEdit,
    ContractCard,
    UploadSidebar,
    SowCard
  },
  data(){
    return{
      systemEditSidebar: false,
      contractData: [],
      sowData: [],
      companyData: {},
      isEditUserSidebarActive:false,
      isUploadSidebarActive:false,
      timelineData: [],
      editingContractData: {},
    }
  },
  methods:{
    setEditingContractData(contractData){
      this.editingContractData = contractData
    },
    getContracts(){
      this.$axios.get("/vendors/companies/get_contracts/" + this.$route.params.id).then((res)=>{
        this.contractData = res.data.data.contracts
      })
    },
    getSows(){
      this.$axios.get("/vendors/companies/get_sows/" + this.$route.params.id).then((res)=>{
        this.sowData = res.data.data.sows
      })
    },
    getCompany(){
      this.$axios.get("/vendors/companies/get_company/" + this.$route.params.id).then((res)=>{
        this.companyData = res.data.data.company
      })
    },
    editCompany(){
      this.isEditUserSidebarActive = true
    },
  },
  created(){
    this.getCompany()
    this.getContracts()
    this.getSows()
  },
}
</script>
