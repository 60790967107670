<template>
  <b-card>
        <div class="d-flex justify-content-start">

          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ companyData.name }}
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="$emit('edit-company')"
              >
                编辑
              </b-button>
<!--               <b-button
                variant="outline-danger"
                class="ml-1"
                @click="delConfirm"
              >
                删除公司
              </b-button> -->
            </div>
          </div>
        </div>
        <b-card-body>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-hashtag"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">税号</span>
            </th>
            <td class="pb-50">
              {{ companyData.tax_number }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-location-dot"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">注册地址</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ companyData.address }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-phone"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">座机</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ companyData.telephone }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-building-columns"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">开户银行</span>
            </th>
            <td class="pb-50">
              {{ companyData.opening_bank }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-money-check-dollar"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">银行账号</span>
            </th>
            <td class="pb-50">
              {{ companyData.bank_account }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">负责人账号</span>
            </th>
            <td class="pb-50">
              {{ companyData.manager_email }}
            </td>
          </tr>
        </table>
       </b-card-body>
      <!-- Right Col: Table -->

  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BCardBody,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BCardBody
  },
  directives: {
    Ripple,
  },
  props: {
    companyData: {
      type: Object,
      required: true,
    },
  },
  methods:{
    delCompany(){
      this.$axios.get("/crm/companies/system/del_company/" + this.$route.params.id ).then((response) => {
        this.$router.go(-1)
      })
    },
    delConfirm() {
      this.$swal({
        title: '删除公司',
        text: "删除后无法撤销，你确定要删除该公司吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delCompany()
        }
      })
    }
  },
}
</script>

<style>

</style>
