<template>
  <b-card  title="项目任务书管理">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2" style="overflow:scroll">
        <span v-if="!sows.length">暂无数据</span>
      
      <b-table class="table-responsive text-nowrap" v-else :fields="fields" :items="sows">
        <template #cell(sow_name)="data">
          <template v-if="!data.item.pending_sow_file_id">
          <a 
            class="text-primary"
            @click="loadFile(data.item.sow_file_id,data.item.sow_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.sow_name }}
          </a>
          </template>

          <template v-else>
          <a 
            class="text-primary"
            @click="loadFile(data.item.pending_sow_file_id,data.item.pending_sow_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.pending_sow_name }}
          </a>
          </template>
        </template>

        <template #cell(project_id)="data">
          <b-badge 
            :href="`/collab/pm/view/${data.item.project_id}`"
            variant="light-primary" 
            >
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            {{ data.item.project_id }}
          </b-badge>
        </template>

        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status === true" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else-if="data.item.sign_status == 'pending'" variant="light-primary">
            审批中
          </b-badge>

          <b-badge v-else-if="data.item.sign_status == 'rejected'" variant="light-danger">
            已驳回
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown v-if="data.item.sign_status == 'pending'"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="data.item.sign_status == 'pending'"
              @click="approve(data.item.sow_id)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">通过</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="data.item.sign_status == 'pending'"
              @click="reject(data.item.sow_id)"
            >
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </div>

    </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge,BDropdown,BDropdownItem,BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
   BLink, BDropdown,BDropdownItem,BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
      generating:false,
      fileDownloading:false,
      sows:[],
      fields:[
        {"key":"sow_id","label":"项目任务书编号"},
        {"key":"sign_status","label":"双方签署状态"},
        {"key":"project_id","label":"项目编号"},
        {"key":"sow_name","label":"项目任务书文件"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
  props:{
    sowData: {
      type: Array,
      default: () => {
        return ([])
      },}
},
  methods:{
    approve(sowId){
      this.$axios.get('/vendors/companies/approve_pending_sow/' + sowId ).then(res=>{
          if(res.data.status == "ok"){
            this.$emit('refresh-sows')
          }
        }
      )
    },
    reject(sowId){
      this.$axios.get('/vendors/companies/reject_pending_sow/' + sowId).then(res=>{
          if(res.data.status == "ok"){
            this.$emit('refresh-sows')
          }
        })
    },
    activeUploadSidebar(){
      this.$emit('active-upload-sidebar')
    },
    loadFile(sowId,fileName){
      this.$axios.get("/get_download_token/" + sowId).then((res)=>{
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
  },
  watch:{
    'sowData':{
      handler(val){
        this.sows = JSON.parse(JSON.stringify(this.sowData))
      },
      deep:true
    }
  }
}
</script>

<style>

</style>
